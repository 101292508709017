<template>
  <div class="home">
    <div class="page-content-fill">
      <!-- <div style="width: 100%; height: 100%; display: flex"> -->
      <div class="dw-content">
        <el-button
          class="dw-item"
          v-for="downloadable in downloadable_list"
          :key="downloadable.url"
        >
          <a :href="downloadable.url" style="text-decoration: none">
            <span class="img-text"
              ><span class="material-icons"> cloud_download </span>
              {{ downloadable.name }}</span
            ></a
          >
        </el-button>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import { mapState } from "vuex";
export default {
  name: "Downloads",
  data() {
    return {
      downloadable_list: [],
    };
  },
  mounted() {
    this.$get_api(
      apiURL.data.available_downloads,
      null,
      this.headers4reqs
    ).then((res) => {
      this.downloadable_list = res.map((d) => {
        return {
          name: d,
          url:
            process.env.VUE_APP_API_BASE_URL +
            apiURL.static.downloads_prefix +
            d,
        };
      });
      console.log(this.downloadable_list);
    });
  },
  watch: {
    center_coordinates(nv) {
      this.init_map(nv.center_coordinates);
    },
  },
  methods: {},
  computed: {
    ...mapState({
      app_name: (state) => state.app_name,
      lang_status: (state) => state.lang_status,
    }),
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/main";
.home {
  width: 100%;
  .page-content-fill {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .dw-content {
    min-width: 200px;

    .el-button + .el-button {
      margin-left: 0px !important;
    }
    .dw-item {
      min-width: 100%;
      height: 50px;
      margin-bottom: 20px;
      .img-text {
        display: flex;
        align-items: center;
        justify-content: center;
        .material-icons {
          margin-right: 0.3rem;
        }
      }
    }
  }
}
</style>